import React, { CSSProperties } from "react";
import { TwoColumnsButton, TwoColumnsDataProps } from "./TwoColumnsDataProps";
import Picture from "../../components/Picture";
import Movie from "../Movie/Movie";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";

const ALIGNMENT_LEFT = "left";

type TwoColumnsProps = {
  data: TwoColumnsDataProps;
};

const TwoColumns: React.FunctionComponent<IDynamicBlockComponent> = ({
  blockData,
}): React.ReactElement => {
  const {
    data: { header, content, element, alignment, picture, video, background },
  } = blockData as TwoColumnsProps;
  const buttons: Array<TwoColumnsButton> = Object.values(element);

  const direction = (): CSSProperties => ({
    flexDirection: alignment === ALIGNMENT_LEFT ? "row" : "row-reverse",
  });

  const getStyle = (
    button: TwoColumnsButton
  ): string => `.twoColumns--baseButton${getButtonUniqClass(button)} {
                        background-color: ${button.background_color};
                        border-color: ${button.border_color};
                        color: ${button.text_color}!important;
                    }

                    .twoColumns--baseButton${getButtonUniqClass(button)}:after {
                        background-color: ${button.background_color};
                    }

                    .twoColumns--baseButton${getButtonUniqClass(
                      button
                    )}:before {
                        background-color: ${button.background_color_hover};
                    }

                    .twoColumns--baseButton${getButtonUniqClass(button)}:hover {
                          color: ${button.text_color_hover}!important;
                          border-color: ${button.border_color_hover};
                          background-color: ${button.background_color_hover};
                    }

                    .twoColumns--baseButton${getButtonUniqClass(
                      button
                    )}:hover > svg {
                            fill: ${button.text_color_hover};
                          }
                    `;

  const getButtonUniqClass = ({
    text_color,
    background_color,
  }: TwoColumnsButton): string => {
    return `${background_color.replace("#", "")}${text_color.replace("#", "")}`;
  };

  const isAlignLeft = (): boolean => alignment === ALIGNMENT_LEFT;

  return (
    <div style={{ backgroundColor: background }}>
      <div className="twoColumns desktop-container" style={direction()}>
        <div
          className={`twoColumns--imgContainer ${
            isAlignLeft() ? "marginRightDesktop-30" : ""
          }`}
        >
          {!video && picture && <Picture data={picture} />}

          {video && <Movie movie={video} />}
        </div>
        <div
          className={`twoColumns--contentContainer ${
            isAlignLeft() ? "" : "marginRightDesktop-30"
          }`}
        >
          {header && <h2 className="twoColumns--header">{header}</h2>}
          <div
            className="twoColumns--content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {buttons && (
            <div className="twoColumns--buttonsContainer">
              {buttons.map((button, index) => (
                <React.Fragment key={index}>
                  <style key={button.url}>
                    {`
                      ${getStyle(button)}
                    `}
                  </style>
                  <span key={index}>
                    <a
                      className={`twoColumns--baseButton modern-button twoColumns--baseButton${getButtonUniqClass(
                        button
                      )}`}
                      key={index}
                      target={button.target}
                      rel="noopener noreferrer"
                      href={button.url}
                    >
                      {button.anchor}
                    </a>
                  </span>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TwoColumns;
