import React from "react";

type MovieProps = {
  background?: string;
  movie: {
    videoEmbed: string;
  };
};

const Movie: React.FunctionComponent<MovieProps> = ({
  background,
  movie,
}): React.ReactElement => {
  return (
    <div
      className={`movie paddingsForBlocks ${
        background === "grey" ? "mainBackgroundGrey" : ""
      }`}
    >
      <div
        className="blocks__desktopWrapper movie__container"
        dangerouslySetInnerHTML={{ __html: (movie && movie.videoEmbed) || "" }}
      />
    </div>
  );
};

export default Movie;
